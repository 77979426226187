<template>
  <div class="h-100">
    <div class="d-flex align-items-center flex-column justify-content-center h-100">
      <div
        id="login-card"
        class="card border-0 shadow"
      >
        <div
          v-if="error"
          class="card-header bg-danger-light text-danger border-0"
        >
          <div class="row align-items-center">
            <div class="col-auto">
              <i class="fas fa-exclamation-triangle text-danger" />
            </div>
            <div class="col pl-0">
              <h6 class="mb-0">
                Token ungültig
              </h6>
            </div>
          </div>
        </div>
        <div class="card-body p-5 text-center">
          <h5
            v-if="!isRegistration"
            class="card-title text-center"
          >
            Geben Sie den 2FA Token aus Ihrer Authenticator-App ein
          </h5>
          <template v-else>
            <h5>Authenticator-App</h5>
            <p>Scannen Sie den QR-Code mit der Authenticator-App und geben Sie den Code ein.</p>
            <img
              class="qr-code"
              :src="qrData"
            >
          </template>
          <form @submit.prevent="submit">
            <basic-input
              v-model="token"
              label="2FA Token"
              class="mb-3"
            />
            <div class="form-row">
              <div class="col">
                <button
                  class="btn btn-secondary w-100"
                  type="button"
                  @click="goBack()"
                >
                  Zurück
                </button>
              </div>
              <div
                class="col"
              >
                <button
                  class="btn btn-primary w-100"
                  type="submit"
                >
                  Senden
                </button>
              </div>
            </div>
            <div class="text-left">
              <router-link
                v-if="!isRegistration && otherMethodsAvailable"
                :to="{name: '2faSelect', query: $route.query}"
                class="d-block mt-3"
              >
                Andere Methode verwenden
              </router-link>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import feathers from '@/api'
import qrcode from 'qrcode'
import BasicInput from '@/components/BaseComponents/BasicInput'

export default {
  components: {
    BasicInput
  },
  data: () => ({
    token: '',
    secret: '',
    qrData: '',
    registrationPending: false,
    error: null
  }),
  computed: {
    twoFactor () {
      return this.$store.getters['auth/user'].user.twoFactor
    },
    client () {
      return this.$store.getters['clients/find']().data[0]
    },
    isSmsAvailable () {
      const globalAvailable = this.client && this.client.twoFactor && this.client.twoFactor.SMS && this.client.twoFactor.SMS.enabled
      const userAvailable = this.twoFactor.SMS && this.twoFactor.SMS.mobileNumber && this.twoFactor.SMS.secret
      return globalAvailable && userAvailable
    },
    isU2fAvailable () {
      const globalAvailable = this.client && this.client.twoFactor && this.client.twoFactor.U2F && this.client.twoFactor.U2F.enabled
      const userAvailable = this.twoFactor.U2F && this.twoFactor.U2F.keyHandle && this.twoFactor.U2F.publicKey
      return globalAvailable && userAvailable
    },
    otherMethodsAvailable () {
      return this.isSmsAvailable || this.isU2fAvailable
    },
    isRegistration () {
      const user = this.$store.getters['auth/user'].user
      const { TOTP } = user.twoFactor
      return !TOTP || !TOTP.secret
    }
  },
  async created () {
    const user = this.$store.getters['auth/user'].user
    if (!user) return this.$router.push('/login')
    if (!user.twoFactor) user.twoFactor = {}
    const { TOTP } = user.twoFactor
    if (!TOTP) {
      this.$set(this.$store.state.auth.user.user.twoFactor, 'TOTP', {})
    }
    if (this.isRegistration) {
      this.registrationPending = true
      const { secret, url } = await feathers.service('totp-registration').create({})
      this.secret = secret
      this.qrData = await qrcode.toDataURL(url)
      this.registrationPending = false
    }
  },
  methods: {
    async goBack () {
      if (this.isRegistration) {
        await this.$router.push(this.$route.query.redirect || '/')
      } else {
        await this.$store.dispatch('auth/logout')
        location.href = '/login'
      }
    },
    async submit () {
      const payload = {
        strategy: '2fa',
        accessToken: this.$store.state.auth.accessToken,
        twoFactorMethod: 'totp',
        totpToken: this.token
      }
      if (this.isRegistration) {
        payload.secret = this.secret
      }
      console.log(payload, this.isRegistration)
      try {
        this.pending = true
        this.error = null
        await this.$store.dispatch('auth/authenticate', payload)
        await this.$router.push(this.$route.query.redirect || '/')
      } catch (error) {
        console.error(error)
        this.error = error
      } finally {
        this.pending = false
      }
    }
  }
}
</script>

<style lang="scss" scoped>
#login-card {
  max-width: 500px;
}

</style>
